import React, { useEffect, useState } from 'react';
import fetch from 'node-fetch';

const PersonioApply = ({ jobId }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ job_position_id: jobId, attributes: [] });
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (isSubmitted) window.scrollTo(0, 0);
  }, [isSubmitted]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      window.alert('Please upload a resume before submitting.');
      return;
    }

    // FileReader is a built-in browser API
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1]; // Get base64 string without the prefix
      const fileName = selectedFile.name;

      try {
        const documentUploadResponse = await fetch('/.netlify/functions/personio-document-upload', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ fileName, fileContent: base64String }),
        });

        if (documentUploadResponse.ok) {
          const documentUploadResult = await documentUploadResponse.json();

          const newFile = {
            uuid: documentUploadResult.uuid,
            original_filename: documentUploadResult.original_filename,
            category: documentUploadResult.category,
          };

          const files = formData.files ? [...formData.files, newFile] : [newFile];
          const formDataWithFiles = { ...formData, files: files };
          console.log('Document uploaded successfully');

          // Make the second API call to attach the files to the applicant here, after the first one completes successfully
          const response = await fetch('/.netlify/functions/personio-application', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formDataWithFiles, recruiting_channel_id: 41576 }),
          });

          if (response.ok) {
            console.log('Application submitted successfully');
            setIsSubmitted(true);
          } else {
            console.error('Error submitting application:', response.statusText);
          }
        } else {
          console.error('Error uploading document:', documentUploadResponse.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleInputChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleAttributeChange = (key, value) => {
    const existingIndex = formData.attributes.findIndex((attr) => attr.id === key);
    const newAttributes = [...formData.attributes];

    if (existingIndex !== -1) {
      newAttributes[existingIndex] = { id: key, value: value };
    } else {
      newAttributes.push({ id: key, value: value });
    }
    setFormData({
      ...formData,
      attributes: newAttributes,
    });
  };

  return isSubmitted ? (
    <div className="personio_form-submitted">
      <p>Thank you. Your application has been submitted.</p>
    </div>
  ) : (
    <form className="personio-apply" onSubmit={handleSubmit} method="post">
      <p className="personio-apply__title">
        <strong>Personal Details</strong>
      </p>
      <input
        type="text"
        id="fname"
        name="fname"
        placeholder="First Name"
        onChange={(event) => handleInputChange('first_name', event.target.value)}
      />
      <br />
      <input
        type="text"
        id="lname"
        name="lname"
        placeholder="Last Name"
        onChange={(event) => handleInputChange('last_name', event.target.value)}
      />
      <br />
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email Address"
        onChange={(event) => handleInputChange('email', event.target.value)}
      />
      <br />
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Phone Number"
        onChange={(event) => handleAttributeChange('phone', event.target.value)}
      />
      <br />
      <div className="personio-apply__upload-resume">
        <label htmlFor="fileUpload">Upload Resume</label>
        <input
          type="file"
          id="fileUpload"
          accept=".pdf, .docx, .doc, .jpg, .png, .txt, .jpeg, .odt, .ods, .xlsx, .rtf, .xls, .pptx, .ppt, .gif, .tif, .tiff, .bmp, .csv, .rar, .gz, .zip, .7z"
          onChange={handleFileChange}
        />
      </div>
      <label htmlFor="message">Experience Summary/Cover Letter</label>
      <textarea
        id="message"
        name="message"
        onChange={(event) => handleInputChange('message', event.target.value)}
      />
      <button type="submit">Submit Application</button>
    </form>
  );
};

export default PersonioApply;
