import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import SectionOpenerJobDetails from '../sections/section-opener/section-opener-job-detail';
import PersonioApply from '../components/personio-apply';

const JobApplyPage = ({ location, data }) => {
  const { id, friendly_id, office, name, employmenttype } = data.job;

  return (
    <Layout className="page--job-detail" pathname={location.pathname}>
      <SEO
        title={`Apply for ${name}`}
        description={`Fill out the details to apply for ${name} at Edenspiekermann, an independent design consultancy working with leading international clients`}
      />
      <div className="job-detail-layout">
        <SectionOpenerJobDetails
          title={name}
          location={office}
          contract={employmenttype}
          hasBackground={false}
        />
        <PersonioApply jobId={id} />
      </div>
    </Layout>
  );
};

JobApplyPage.propTypes = {
  data: shape({
    job: shape({
      id: string.isRequired,
      name: string.isRequired,
      office: string.isRequired,
      employmenttype: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default JobApplyPage;

export const pageQuery = graphql`
  query ($id: String!) {
    job: jobsJson(id: { eq: $id }) {
      id: id
      friendly_id: id
      name
      office
      employmenttype
    }
  }
`;
